
import { useState } from 'react';

export const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const toggleNavbar = () => {
     setIsSidebarOpen(!isSidebarOpen);
   };
  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleOptionClick(option) {
    window.location.href = option;
  }

  function DropdownMenu() {
    return (
      <div className="relative inline-block text-left ">
        <div>
          <button
            type="button"
            className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-base text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 md:w-auto"
            onClick={toggleDropdown}
          >
            Products
            <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 14l6-6H4z" />
            </svg>
          </button>
        </div>

        {isOpen && (
          <ul className="absolute z-20 mt-4  bg-white rounded-md shadow-lg p-2" style={{cursor: "pointer"}}>
            <li className="px-4 py-2 font-medium text-base hover:bg-green-100" onClick={() => handleOptionClick("/crop")}>
              Crops
            </li>
            <li className="px-4 py-2 font-medium text-base hover:bg-green-100" onClick={() => handleOptionClick("/vegetables")}>
              Vegetables
            </li>
          </ul>
        )}
      </div>
    );
  }
  return (
    
<nav class="bg-white font-inter px-4 sm:px-4  ">
  <div class="container flex flex-wrap items-center justify-between mx-auto">
    {/* <a href="https://flowbite.com/" class="flex items-center"> */}
    <img src="./logo1.PNG" class="h-20 mr-4 sm:h-18" alt="Flowbite Logo" />
        {/* <span class="self-center text-xl font-semibold whitespace-nowrap font-inter">Flowbite</span> */}
    {/* </a> */}
    <button onClick={toggleNavbar} data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="navbar-default" aria-expanded="false">
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div className={`w-full md:block md:w-auto ${isSidebarOpen ? 'block' : 'hidden'}`} id="navbar-dropdown">
          <ul className="flex flex-col p-4 mt-2 border-t border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
  <li>
   <a href="/#features" class="block py-2 pl-3 pr-4 text-base text-gray-700 rounded hover:bg-green-500 hover:text-green md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 md:dark:hover:text-green">Features</a>
   </li>
   <li>
     <DropdownMenu />
    </li>
    <li>
   <a href="/#testimonials" class="block py-2 pl-3 pr-4 text-base text-gray-700 rounded hover:bg-green-500 hover:text-green md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 md:dark:hover:text-green">Testimonials</a>
   </li>

    <li>
      <a href="/#about" class="block py-2 pl-3 pr-4 text-base text-gray-700 rounded hover:bg-green-500 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 md:dark:hover:text-green">About Us</a>
    </li>
    <li>
      <a href="/#contact" class="block py-2 pl-3 pr-4 text-base text-gray-700 rounded hover:bg-green-500 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 md:dark:hover:text-green">Contact Us</a>
    </li>
    </ul>
    </div>
  </div>
</nav> 
  )
}

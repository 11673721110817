export const Header = (props) => {
  return (
    <div className='relative bg h-200 w-full bg-left-top bg-cover py-64'>
      <div className="flex flex-col w-1/2">
        <h1 className="mb-2 ml-12 text-5xl">
          <b>Empowering a sustainable <span className="text-green-600">agricultural</span>  future with efficient and reliable seeds.</b>
        </h1>
        <p className="text-xl font-medium ml-12 text-white">
          "Growing the future with every seed"
        </p>
      </div>
      <div className="absolute bottom-16 transform left-1/3 -translate-x-1/2">
        <h1 className="text-4xl text-center text-white font-bold fade-in">
          100+
        </h1>
        <p className="text-lg font-bold text-white">
          Satisfied Customers
        </p>
      </div>
    </div>
  );
}

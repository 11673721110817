import React from 'react'
import { Navigation } from "./navigation";
function Catlog() {
  return (
<>
<Navigation />
<div class="mt-1 border border-gray-200 shadow-sm bg-gray-50 md:bg-white"></div>



<h2 class="mb-4 text-3xl mt-6 text-semibold text-center font-inter leading-none tracking-tight text-gray-900 md:text-4xl dark:text-gray">Product Catlog</h2>
<div class="container my-12 mx-auto px-4 font-inter md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">

        {/* <!-- Column --> */}
        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END 
        </div>
        {/* <!-- END Column --> */}

        {/* <!-- Column --> */}
        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>
        {/* {/* <!-- END Column --> */}

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>

        <div class="my-1 px-1 w-3/12 md:w-1/2 lg:my-4 lg:px-4 lg:w-3/12">
            <article class="overflow-hidden rounded-lg shadow-lg">
                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random" />
                </a>
                <header class="flex items-center justify-between leading-tight p-1 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                </header>

                <footer class="flex items-center justify-between leading-none p-1 md:p-4">
                <ul class="text-2xs mt-1 list-disc list-inside text-black-100 leading-relaxed">
                  
                  <li>Responsive</li>
                                                                                    
                  <li> Mobile-friendly</li>
                                                                                    
                  <li> Media queries</li>
                                                                                    
                  <li> 20MB of JavaScript</li>
                                                                            
                </ul>
                                                                                                                      
                                                                      
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>
    </div>
</div>
</div>
</>)};
export default Catlog;
import React, { useState, useEffect } from 'react';
import { Navigation } from '../navigation';
import { useParams } from 'react-router-dom';

const SubCrop = () => {
  const { cropId } = useParams(); // Extract the cropId from URL parameter
  const [subProducts, setSubProducts] = useState([]);
  const [mainCropName, setMainCropName] = useState('');

  useEffect(() => {
    // Fetch main crop name from the backend API
    fetch(`${process.env.REACT_APP_BASE_URL}/crops/${cropId}`)
      .then((response) => response.json())
      .then((data) => setMainCropName(data.product_name))
      .catch((error) => {
        console.error('Error fetching main crop name:', error);
        setMainCropName(''); // Set to an empty string or a default value on error
      });
  
    // Fetch sub-products for the selected main product from the backend API
    fetch(`${process.env.REACT_APP_BASE_URL}/subcrops/${cropId}/subProducts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched sub-products:', data);
        setSubProducts(data);
      })
      .catch((error) => {
        console.error('Error fetching sub-products:', error);
        setSubProducts([]); // Set an empty array in case of error
      });
  }, [cropId]);
  

  return (
    <>
    <div className="border-b border-gray-300">
        <Navigation />
      </div>
      <nav className="pt-5 font-inter pb-10 sm:px-4 border-gray-300 text-4xl text-center">
        <span>{mainCropName}</span>
      </nav>

      {subProducts.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 px-8">
          {subProducts.map((subProduct) => (
            <div key={subProduct._id} className="font-inter transition duration-300 transform hover:scale-105">
              <article className="overflow-hidden rounded-lg shadow-lg hover:shadow-xl">
                <a>
                  <img alt="SubCrop" className="block h-56 w-full object-cover" src={subProduct.product_image} />
                </a>
                <header className="flex items-center justify-between leading-tight p-1">
                  <h1 className="text-lg">
                    <a className="ml-4 text-xl font-medium text-black">
                      {subProduct.product_name}
                    </a>
                    
                  </h1>
                  
                 
                </header>
                <p className="text-gray-600 mb-2 ml-4">{subProduct.description}</p>
              </article>
            </div>
          ))}
        </div>
      ) : (
        <p>No sub-products available for this crop.</p>
      )}
    </>
  );
};

export default SubCrop;

import React, { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Dialog } from '@headlessui/react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function SubCropModal({ onClose, isOpen, onCropAdded }) {
  const [title, setCropTitle] = useState('');
  const [description, setCropDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [mainProducts, setMainProducts] = useState([]);
  const [selectedMainProduct, setSelectedMainProduct] = useState('');
  const cancelButtonRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  
  function handleFileSelect(event) {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  }

  useEffect(() => {
    // Fetch main products from the backend API
    fetchMainCrops();
  }, []);

  async function fetchMainCrops() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subveg/getveg`); // Endpoint to fetch main veg
      if (response.ok) {
        const data = await response.json();
        setMainProducts(data); // Assuming the response data has the same format as the mainProducts state
      } else {
        throw new Error('Failed to fetch main veg');
      }
    } catch (error) {
      console.error('Error fetching main veg:', error);
    }
  }
  
  async function handleMainProductSelect(event) {
    const selectedMainProductId = event.target.value;
    setSelectedMainProduct(selectedMainProductId);
  }

  async function handleSubCropSubmit(event) {
    event.preventDefault();

    // Create a FormData object to store the form data
    const formData = new FormData();
    formData.append('product_name', title);
    formData.append('description', description);
    formData.append('main_product_id', selectedMainProduct)

    // Create a new File object with the selected file
    if (selectedFile) {
      const file = new File([selectedFile], selectedFile.name, {
        type: selectedFile.type,
      });
      formData.append('product_image', file);
    }
    
    try {
      // Send the form data to the server using the fetch function
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subveg/addsubveg`, {
        method: 'POST',
        body: formData,

      });

      if (response.ok) {
        // If the response from the server is ok, close the modal
        onClose();
        // Call the onCropAdded callback to show the toast
        onCropAdded();
      } else {
        // Handle any errors that occur during the request
        const data = await response.json();
        setErrorMessage(data.error || 'Network response was not ok');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setErrorMessage('There was an error while processing your request.');
    }
  }
  return (
    <Transition.Root show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      initialFocus={cancelButtonRef}
      onClose={onClose}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </Dialog.Overlay>
        </Transition.Child>
  
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
  
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <h1 className="text-lg font-semibold mb-4">Add Vegetable</h1>
                  <div className="mb-4">
                    <label htmlFor="mainProduct" className="block text-sm font-medium text-gray-700">
                      Select Main Vegetable:
                    </label>
                    <select
                      id="mainProduct"
                      value={selectedMainProduct}
                      onChange={handleMainProductSelect}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      className="mt-1 p-2 block w-full shadow-sm ring-gray-500 ring-1 placeholder:text-gray-400 focus:ring-gray-500 focus:ring-inset rounded-md focus:ring-2 sm:text-sm sm:leading-6"
                    >
                      <option className=' text-base ' value="">Select a Main Vegetable</option>
                      {mainProducts.map((mainProduct) => (
                        <option className=' text-base ' key={mainProduct._id} value={mainProduct._id}>
                          {mainProduct.product_name}
                        </option>
                      ))}
                    </select>
                  </div>
                <div className="mb-4">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Vegetable Title:
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={title}
                    onChange={(e) => setCropTitle(e.target.value)}
                    className="mt-1 p-2 block w-full shadow-sm ring-gray-500 ring-1 placeholder:text-gray-400 focus:ring-gray-500 focus:ring-inset rounded-md focus:ring-2 sm:text-sm sm:leading-6"
                  />
                </div>
                  <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Vegetable Description:
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows="3"
                      value={description}
                      onChange={(e) => setCropDescription(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 pl-2 pr-4 text-gray-900 ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                  <div className="mb-4">
              <label htmlFor="fileInput" className="block text-sm font-medium text-gray-700">
                Vegetable Image:
              </label>
              <input
                aria-describedby="file"
                id="fileInput"
                type="file"
                name="product_image" // Change "product_image" to "file"
                onChange={handleFileSelect}
                className="block w-full text-sm py-1.5 pl-2 pr-4 text-black border-0 border-gray-300 rounded-md cursor-pointer bg-gray-50 focus:outline-none dark:bg-green-600 dark:border-green-600 dark:placeholder-gray-400"
              />
            </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleSubCropSubmit}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => onClose()}
                ref={cancelButtonRef}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </Dialog>
  </Transition.Root>
  );}  
import { useState } from 'react'
import emailjs from '@emailjs/browser';

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const [isMessageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_5kb3s6n", "template_bxtw5xx", e.target, "CKmPQiZmqWUC1hZ0S").then(
      (result) => {
        console.log(result.text);
        setMessageSent(true);
        setState(initialState); // Reset the form
        setTimeout(() => {
          setMessageSent(false);
        }, 4040); // Hide the alert after 4 seconds
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
<>
  {/* <link rel="stylesheet" href="https://cdn.tailgrids.com/tailgrids-fallback.css" /> */}

<section id='contact' class="bg-white font-inter  py-20 lg:py-[120px] overflow-hidden relative z-10">
   <div class="container">
      <div class="flex flex-wrap lg:justify-between -mx-4">
         <div class="w-full lg:w-1/2 xl:w-6/12 px-4">
            <div class="max-w-[540px] mb-8 ml-8 lg:mb-0">
               <span class="block mb-4 text-4xl text-primary font-semibold">
               Contact Us
               </span>
               <p class="text-base text-body-color leading-relaxed mb-12">
               Do you have questions about our seed varieties or need help with placing an order? Want to share feedback about our products or have suggestions for new varieties? Let us know - we're here to help you grow the best crops possible.
               </p>


               <div class="flex items-center">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                   <path fill-rule="evenodd" d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z" clip-rule="evenodd" />
                 </svg>
                 <h5 class="ml-2 text-xl">Office Address</h5>
               </div>
               <p class="text-gray-500 mt-2 mb-8">Plot No. 15,16, 17, Salman Hills, Dilras Colony. Aurangabad-431001 Maharashtra, India.</p>
               


              <div class="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                  <path d="M19.006 3.705a.75.75 0 00-.512-1.41L6 6.838V3a.75.75 0 00-.75-.75h-1.5A.75.75 0 003 3v4.93l-1.006.365a.75.75 0 00.512 1.41l16.5-6z" /> <path fillRule="evenodd" d="M3.019 11.115L18 5.667V9.09l4.006 1.456a.75.75 0 11-.512 1.41l-.494-.18v8.475h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3v-9.129l.019-.006zM18 20.25v-9.565l1.5.545v9.02H18zm-9-6a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75H9z" clipRule="evenodd" />
                  </svg>
                  <h5 class="ml-2 text-xl">Plant Address</h5>
              </div>
              
              <p class="text-gray-500 mb-8"> Gut No. 54, Plot No. 9/10, Shajapur, MIDC Waluj, Aurangabad-431136. Maharashtra, India.</p>
              
               

               {/* Call */}
               <div class="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                  </svg>
                  <h5 class="ml-2 text-xl">Call </h5>
              </div>
              <p class="text-gray-500 mb-8">+91 9595959514
</p>
               
               {/* Email */}
               <div class="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" /> 
                  </svg>
                  <h5 class="ml-2 text-xl">Email</h5>
              </div>
              <p class="text-gray-500 mb-2">mohib@globalagritecseeds.in</p>


            </div>
         </div>
         <div className="w-full mb-14 lg:w-1/2 xl:w-5/12 px-4">
      <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-xl">
        <form onSubmit={sendEmail}>
          <div className="mb-6">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-black dark:text-black">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-black dark:text-black">
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              placeholder="name@global.com"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black dark:text-black">
              Your Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={handleChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              placeholder="+91 00000 XX999"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-black dark:text-black">
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={handleChange}
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Leave a comment..."
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-green-500 sm:w-fit hover:bg-green-600 focus:ring-4 focus:outline-none  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Send message
            </button>
          </div>
        </form>
      </div>

      {isMessageSent && (
        <div className="fixed bottom-5 right-5 p-4 bg-green-500 text-white rounded-md shadow-md">
          Your message successfully sent!
        </div>
      )}
    </div>
      </div>
      
   </div>
</section>
<footer class=" bg-green-400  shadow md:px-6 md:py-8">
  <span class="block text-sm text-black-500 sm:text-center">© <h1  class="hover:underline">Global AgriTech Seeds™</h1>. All Rights Reserved.</span>
</footer>


      
      
</>)
}

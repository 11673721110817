export const About = (props) => {
  return (
    // <!-- component -->
<div id="about" class="py-16 font-inter bg-white">  
  <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div class="md:5/12 lg:w-5/12">
          <img src="https://tailus.io/sources/blocks/left-image/preview/images/startup.png" alt="image" loading="lazy" width="" height=""/>
        </div>
        <div class="md:7/12 lg:w-6/12">
            <h2 class="text-3xl text-gray-900 font-bold md:text-4xl">
              Planting the Seeds of Success: Learn More About Our Agriculture Business
            </h2>

            <p class="mt-6 text-m font-semibold text-gray-600">
              We are a dedicated team of professionals who are committed to providing high-quality seeds to farmers and agricultural businesses. Our passion for agriculture drives us to constantly innovate and improve our products to meet the ever-changing needs of the industry.
              With years of experience in the seed business, we have built strong relationships with farmers and suppliers to ensure that we can offer the best seeds at competitive prices.
            </p>
            <p class="mt-6 text-m font-semibold text-gray-600">
              Our goal is to empower farmers with the tools and resources they need to grow healthy and abundant crops, while also protecting the environment.
              We believe in sustainability and responsible farming practices, which is why we offer a wide variety of non-GMO and organic seed options. We understand that every farm is unique, and we work closely with our customers to tailor our products and services to their specific needs.
              At our Agriculture Seed Business Firm, we are more than just a seed supplier. We are a partner in your farming journey, and we are committed to your success. Contact us today to learn more about our products and services, and to see how we can help you grow your farm.
            </p>
        </div>
      </div>
  </div>
</div>
  );
};

import { useState, useEffect } from "react";
import Home from "./components/home";
import Addcrop from "./admin/addcrop";
import Dashboard from "./admin/dashboard";
import Catlog from "./components/catlog";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import ManageContent from "./admin/managecontent";
import { Crop } from "./components/pages/crop";
import { Vegetables } from "./components/pages/vegetables";
import SubCrop from "./components/pages/subcrop";
import Addveg from "./admin/addveg";
import CropModal from "./admin/components/cropmodal";
import SubVeg from "./components/pages/subveg";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/catlog" element={<Catlog />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/addcrop" element={<Addcrop />} />
        <Route path="/managecontent" element={<ManageContent />} />
        <Route path="/crop" element={<Crop />} />
        <Route path="/vegetables" element={<Vegetables />} />
        <Route path="/addveg" element={<Addveg />} />
        <Route path="/cropmadal" element={<CropModal />} />
        <Route path="/crop/:cropId/subcrop" element={<SubCrop />} /> 
        <Route path="/vegetable/:vegId/subveg" element={<SubVeg />} /> 
      </Routes>
    </div>
  );
};

export default App;

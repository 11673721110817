import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function VegetableModal({ onClose, isOpen, onVegAdded}){
  const cancelButtonRef = useRef(null);
  const [title, setCropTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  function handleFileSelect(event) {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  }

  async function handleCropSubmit(event) {
    event.preventDefault();

    // Create a FormData object to store the form data
    const formData = new FormData();
    formData.append('product_name', title);
  
    // Create a new File object with the selected file
    if (selectedFile) {
      const file = new File([selectedFile], selectedFile.name, {
        type: selectedFile.type,
      });
      formData.append('product_image', file);
    }  

    try {
      // Send the form data to the server using the fetch function
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vegetables/addveg`, {
        method: 'POST',
        body: formData,
       
      });

      if (response.ok) {
        // If the response from the server is ok, close the modal
        onClose();
        onVegAdded();
      } else {
        // Handle any errors that occur during the request
        const data = await response.json();
        setErrorMessage(data.error || 'Network response was not ok');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setErrorMessage('There was an error while processing your request.');
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
            <Dialog.Overlay className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </Dialog.Overlay>
        </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div>
                  <h1 className="text-lg font-semibold mb-4">Add Vegetables</h1>
                  <div className="mb-4">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Vegetable Title:
      </label>
        <input
          type="text"
          name="title"
          id="title"
                      value={title}
          onChange={(e) => setCropTitle(e.target.value)}
          className="mt-1 p-2 block w-full shadow-sm ring-gray-500 ring-1 placeholder:text-gray-400 focus:ring-gray-500 focus:ring-inset rounded-md focus:ring-2 sm:text-sm sm:leading-6"

        />
      </div>
                  <div className="mb-4">
                  <label htmlFor="file" className="block text-sm font-medium text-gray-700">
                      Vegetable Image:
                    </label>
        <input
                    aria-describedby="file"
                    id="fileInput"
          type="file"
                    name="file" // Change "product_image" to "file"
                      onChange={handleFileSelect}
                      className="block w-full text-sm py-1.5 pl-2 pr-4 text-black border-0 border-gray-300 rounded-md cursor-pointer bg-gray-50 focus:outline-none dark:bg-green-600 dark:border-green-600 dark:placeholder-gray-400"
                    />
    </div>
                  {errorMessage && (
                    <div className="text-red-600 mb-4">{errorMessage}</div>
                  )}
  </div>
</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
                  onClick={handleCropSubmit}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700  sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => onClose()}
              ref={cancelButtonRef}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
            </div>
        </Transition.Child>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} />
  </Dialog>
</Transition.Root>
  );
  }

import TextEditor from "./components/texteditor";
import { useCallback, useRef } from "react";


const saveData = async (content) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/aboutus/saveAbout`, {
    method: 'POST',
    body: JSON.stringify({ text: content }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const result = await response.json();
  console.log(result);
};

export const ManageContent = () => {
  const editorRef = useRef();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const content = editorRef.current.getContent();
    saveData(content);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <nav className="bg-white border-b mb-4 font-inter border-gray-300 px-2.5 pb-6 sm:px-4 rounded ">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <a href="/" className="flex items-center">
            <span className="self-center text-4xl ml-8 mt-4 font-semibold whitespace-nowrap text-black">Manage Content</span>
          </a>
          <button type="submit" className="text-white mt-4 ml-4 px-3 mr-8 py-1.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-md text-m  text-center inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg> Save
          </button>
        </div>
      </nav>
      <span className="text-xl font-inter ml-6 font-medium">About Us</span>
      <TextEditor ref={editorRef} />
    </form>
  );
};

export default ManageContent;
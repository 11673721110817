import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubVegModal from "./components/subvegmodal";

const AddSubVeg = (props) => {
  const [isVegModalOpen, setIsVegModalOpen] = useState(false);
  const [veg, setVeg] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [vegToDeleteId, setVegToDeleteId] = useState('');

  const openVegModal = () => {
    setIsVegModalOpen(true);
  };

  const closeVegModal = () => {
    setIsVegModalOpen(false);
  };

  const handleDeleteVeg = (vegId) => {
    setVegToDeleteId(vegId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteCrop = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subveg/deleteVeg/${vegToDeleteId}`, {
        method: 'DELETE',
      });

      setVeg((prevVeg) => prevVeg.filter((veg) => veg._id !== vegToDeleteId));
      toast.success('veg deleted successfully!', {
        style: { color: '#e53e3e' }, // Change to the red color you prefer
      });
    } catch (error) {
      console.error('Error deleting veg:', error);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const cancelDeleteVeg = () => {
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/subveg/allsubveg`)
      .then((response) => response.json())
      .then((data) => setVeg(data))
      .catch((error) => console.error('Error fetching crop data:', error));
  }, []);

  return (
    <>
      <nav className="bg-white border-b border-gray-300 px-4 py-3 sm:px-6">
        <div className="container flex items-center justify-between mx-auto">
          <a className="flex items-center">
            <span className="text-3xl font-medium text-black">Vegetable Category</span>
          </a>
          <div className="w-full md:w-auto" id="navbar-default">
            <button
              type="button"
              onClick={openVegModal}
              className="text-white mt-4 px-3 py-1.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-md text-m text-center inline-flex items-center md:ml-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 md:mr-2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>{' '}
              <span className="hidden md:inline">Add Sub Vegetables</span>
            </button>
          </div>
        </div>
      </nav>

      {isVegModalOpen && <SubVegModal onClose={closeVegModal} isOpen={isVegModalOpen} onVegAdded={() => toast.success('Veg Category added successfully!')} />}

      <div className="grid grid-cols-1 px-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 w-11/12">
        {veg.map((crop) => (
          <div key={crop._id} className="overflow-hidden rounded-lg shadow-lg border border-gray-200 transition duration-300 transform hover:scale-105 hover:border-gray-300">
            <a>
              <img alt="SubCrop" className="block h-auto w-full" src={crop.product_image} />
            </a>
            <div className="p-2">
              <h1 className="text-2xl font-semibold mb-2">
                <a className="text-black hover:text-red-500 transition duration-300">
                  {crop.product_name}
                </a>
              </h1>
              <p className="text-lg text-gray-700 mr-2 mb-2">
                Main Product - {crop.main_product_product_name}
              </p>
              <p className="text-gray-600 mb-4">
                {crop.description}
              </p>
              <div className="flex justify-center items-center">
                <button
                  onClick={() => handleDeleteVeg(veg._id)}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md flex items-center space-x-1"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <p className="text-lg font-medium">Are you sure you want to delete this vegetable?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={cancelDeleteVeg}
                className="mr-2 px-4 py-2 text-white bg-gray-500 hover:bg-gray-600 rounded"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteCrop}
                className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer position="bottom-right" autoClose={3000} />
    </>
  );
};

export default AddSubVeg;


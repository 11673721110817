import React, { useState, useEffect } from 'react';
import CropModal from './components/cropmodal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddCrop = (props) => {
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [crops, setCrops] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [cropToDeleteId, setCropToDeleteId] = useState('');

  const openCropModal = () => {
    setIsCropModalOpen(true);
  };

  const closeCropModal = () => {
    setIsCropModalOpen(false);
  };

  const handleDeleteCrop = async (cropId) => {
    try {
      // Display the delete confirmation modal
      setCropToDeleteId(cropId);
      setShowDeleteConfirmation(true);
    } catch (error) {
      console.error('Error deleting crop:', error);
    }
  };

  const confirmDeleteCrop = async () => {
    try {
      // Send delete request to the backend API
      await fetch(`${process.env.REACT_APP_BASE_URL}/crops/deleteCrop/${cropToDeleteId}`, {
        method: 'DELETE',
      });

      // Remove the deleted crop from the local crops state
      setCrops((prevCrops) => prevCrops.filter((crop) => crop._id !== cropToDeleteId));
      toast.success('Crop deleted successfully!', {
        style: { color: '#e53e3e' }, // Change to the red color you prefer
      });
    } catch (error) {
      console.error('Error deleting crop:', error);
    } finally {
      // Close the delete confirmation modal
      setShowDeleteConfirmation(false);
    }
  };

  const cancelDeleteCrop = () => {
    // Close the delete confirmation modal without deleting the crop
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    // Fetch crop data from the backend API
    fetch(`${process.env.REACT_APP_BASE_URL}/crops/allcrops`)
      .then((response) => response.json())
      .then((data) => {
        setCrops(data);
        setIsCropModalOpen(true); // Open the modal after fetching the data
      })
      .catch((error) => console.error('Error fetching crop data:', error));

    // Add a listener for the "esc" key to close the modal
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeCropModal();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    // Cleanup: Remove the listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <>
   <nav className="bg-white border-b border-gray-300 px-4 py-3 sm:px-6">
        <div className="container flex items-center justify-between mx-auto">
          <a className="flex items-center">
            <span className="text-3xl font-medium text-black">Crops</span>
          </a>
          <div className="w-full md:w-auto" id="navbar-default">
            <button
              type="button"
              onClick={openCropModal}
              className="text-white mt-4 px-3 py-1.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-md text-m text-center inline-flex items-center md:ml-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 md:mr-2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>{' '}
              <span className="hidden md:inline">Add Crops</span>
            </button>
          </div>
        </div>
      </nav>
    {isCropModalOpen && <CropModal isOpen={isCropModalOpen} onClose={closeCropModal}  onCropAdded={() => toast.success('Crop added successfully!')} />}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-4 gap-4 px-4">
        {crops.map((crop) => (
          <div key={crop._id} className="font-inter">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a>
                {/* Increase the height value below */}
                <img alt="Crop" className="block h-48 object-cover w-full" src={crop.product_image} />
              </a>
              <header className="flex items-center justify-between leading-tight p-1 md:p-4">
                <h1 className="text-md md:text-lg">
                  <a className="no-underline hover:underline text-xl font-medium text-black">
                    {crop.product_name}
                  </a>
                </h1>
              </header>
              <div className="flex justify-center items-center bottom-0 right-0">
                <button
                  onClick={() => handleDeleteCrop(crop._id)}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 w-full px-4 flex justify-center items-center space-x-1"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                  <span>Delete</span>
                </button>
              </div>
            </article>
          </div>
        ))}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <p className="text-lg font-medium">Are you sure you want to delete this crop?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={cancelDeleteCrop}
                className="mr-2 px-4 py-2 text-white bg-gray-500 hover:bg-gray-600 rounded"
              >
                Cancel
              </button>
              <button
                 onClick={confirmDeleteCrop}
                className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer position="bottom-right" autoClose={3000} />
    </>
  );
};

export default AddCrop;

import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TextEditor() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <div style={{ marginLeft: '20px' }}>
  <Editor
    apiKey='ue7hgt2ramiw20x96sceyo2q9k1hh681hw6itiwnmlinket8'
    onInit={(evt, editor) => editorRef.current = editor}
    initialValue="<p>This is the initial content of the editor.</p>"
    init={{
      height: 400,
      width: 900,
      menubar: false,
      plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
      ],
      toolbar: 'undo redo | blocks | ' +
        'bold italic forecolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
      content_style: 'body { font-family:Helvetica,Arial,Inter,sans-serif; font-size:14px }'
    }}
  />
</div>

 
    </>
  );
}
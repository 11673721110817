import React, { useState, useEffect } from 'react';
import { Navigation } from '../navigation';
import { Link } from 'react-router-dom';
import SubCrop from './subcrop'; // Import the SubProductView component

export const Crop = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [crops, setCrops] = useState([]);
  const [subProductsAvailable, setSubProductsAvailable] = useState(false); // Add a state to track sub-products availability

  useEffect(() => {
    // Fetch crop data from the backend API
    fetch(`${process.env.REACT_APP_BASE_URL}/crops/allcrops`)
      .then((response) => response.json())
      .then((data) => setCrops(data))
      .catch((error) => console.error('Error fetching crop data:', error));
  }, []);

  const handleMainProductClick = async (cropId) => {
    try {
      // Fetch sub-products for the selected main product from the backend API
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subcrops/${cropId}/subProducts`);
      const data = await response.json();

      if (data.length > 0) {
        setSelectedProductId(cropId);
        setSubProductsAvailable(true);
      } else {
        setSelectedProductId(null);
        setSubProductsAvailable(false);
      }
    } catch (error) {
      console.error('Error fetching sub-products:', error);
    }
  };

  return (
    <>
      <div className="border-b border-gray-300">
        <Navigation />
      </div>

      <nav className="pt-5 font-inter pb-10 sm:px-4 border-gray-300 text-4xl text-center">
        <span>Crops</span>
      </nav>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 px-8">
        {crops.map((crop) => (
          <div key={crop._id} className="hover:scale-105 cursor-pointer">
           <Link to={subProductsAvailable ? `/crop/${crop._id}/subcrop` : ''} onClick={() => handleMainProductClick(crop._id)}>
              <article className={`overflow-hidden rounded-lg shadow-lg hover:shadow-xl ${selectedProductId === crop._id ? 'border-4 border-blue-500' : ''}`}>
                <img alt="Crop" className="block h-56 w-full object-cover" src={crop.product_image} />
                <header className="flex items-center justify-between leading-tight p-1">
                  <h1 className="text-lg">
                    <a className="ml-4 text-xl font-medium text-black">
                      {crop.product_name}
                    </a>
                  </h1>
                </header>
              </article>
            </Link>
          </div>
        ))}
      </div>

      {selectedProductId !== null && <SubCrop selectedProductId={selectedProductId} />}

    </>
  );
};

import React, { useState } from "react";
import AddCrop from "./addcrop";
import AddSubCrop from "./addsubcrop";
import AddSubVeg from "./addsubveg";
import AddVeg from "./addveg";

export const Dashboard = (props) => {
   const [selectedMenu, setSelectedMenu] = useState("addcrop");
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const toggleSidebar = () => {
     setIsSidebarOpen(!isSidebarOpen);
   };
   return (
     <>
       <nav className="fixed top-0 z-50 w-full bg-white border-b font-inter border-gray-200">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <button
              onClick={toggleSidebar}
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                     clipRule="evenodd"
                     fillRule="evenodd"
                     d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                   ></path>
              </svg>
            </button>
            <a className="flex ml-2 text-center md:mr-24">
            <img
                   src="/logo1.PNG"
                   className="h-12 "
                   alt="Global Agritech Logo"
                 />
            </a>
          </div>
        </div>
      </nav>
 
       <aside
        id="logo-sidebar"
        className={`fixed top-0 font-inter left-0 z-40 w-64 h-screen pt-20 transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200 sm:translate-x-0 `}
        aria-label="Sidebar"
      >
         <div className="h-full px-3 pb-4 overflow-y-auto bg-white " >
           <ul className="space-y-2">
             <li>
               <a
                 className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ${
                   selectedMenu === "addcrop" && "bg-gray-100" 
                 }`}
                 onClick={() => setSelectedMenu("addcrop")}
               >
                 <img src="https://img.icons8.com/ios-glyphs/30/null/wheat.png" />
                 <span className="flex-1 ml-3 text-lg font-medium whitespace-nowrap" style={{ cursor: 'pointer' }}>
                   Add Crops
                 </span>
               </a>
             </li>
             <li>
               <a
                 className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ${
                   selectedMenu === "addsubcrop" && "bg-gray-100"
                 }`}
                 onClick={() => setSelectedMenu("addsubcrop")}
               >
                 <img src="https://img.icons8.com/ios-glyphs/30/null/wheat.png" />
                 <span className="flex-1 ml-3 text-lg font-medium whitespace-nowrap" style={{ cursor: 'pointer' }}>
                  Crop Category
                 </span>
               </a>
             </li>
             
             <li>
              <a
                className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${
                  selectedMenu === "addveg" && "bg-gray-100"
                }`}
                onClick={() => setSelectedMenu("addveg")}
              >
                <img src="https://img.icons8.com/ios/30/null/carrot.png" />
                <span className="flex-1 ml-3 text-lg font-medium  whitespace-nowrap" style={{ cursor: 'pointer' }}>
                  Add Vegetables
                </span>
              </a>
            </li>
            <li>
               <a
                 className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ${
                   selectedMenu === "addsubveg" && "bg-gray-100"
                 }`}
                 onClick={() => setSelectedMenu("addsubveg")}
               >
                 <img src="https://img.icons8.com/ios/30/null/carrot.png" />
                 <span className="flex-1 ml-3 text-lg font-medium whitespace-nowrap" style={{ cursor: 'pointer' }}>
                  Vegetable Category
                 </span>
               </a>
             </li>
            {/* <li>
              <a
                className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ${
                  selectedMenu === "managecontent" && "bg-gray-100"
                }`}
                onClick={() => setSelectedMenu("managecontent")}
              >
                <img src="https://img.icons8.com/ios-filled/30/null/content.png" />
                <span className="flex-1 ml-3 text-lg font-medium  whitespace-nowrap">
                  Manage Content
                </span>
              </a>
            </li> */}
      </ul>
   </div>
</aside>

<div className="p-0 sm:ml-64">
        <div className=" rounded-m mt-16">
          {selectedMenu === "addcrop" && <AddCrop />}
          {selectedMenu === "addsubcrop" && <AddSubCrop />}
          {selectedMenu === "addveg" && <AddVeg />}
          {selectedMenu === "addsubveg" && <AddSubVeg />}
          {/* {selectedMenu === "managecontent" && <ManageContent />} */}
        </div>
      </div>
</> 
   )};

    export default Dashboard;